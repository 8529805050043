<template>
  <v-navigation-drawer
    v-if="isDesktop"
    v-model="state.open"
    content-class="cart-menu cart-menu--desktop"
    location="right"
    width="500"
    style="z-index: 2402"
    absolute
    temporary
  >
    <v-card-actions>
      <v-spacer />
      <v-btn icon @click="close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-card-actions>
    <slot />
  </v-navigation-drawer>
  <v-overlay
    v-else
    v-model="state.open"
    content-class="cart-menu cart-menu--mobile "
    style="z-index: 2402"
    close-on-content-click
    close-on-back
  >
    <v-slide-y-reverse-transition>
      <v-sheet
        v-if="state.open"
        class="rounded-t-xl overflow-hidden"
        style="width: 100vw"
        @click.stop
      >
        <v-card-actions>
          <v-spacer />
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-actions>
        <slot />
      </v-sheet>
    </v-slide-y-reverse-transition>
  </v-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DynamicMenu",
  emits: ["update:modelValue"],
  setup() {
    const breakpoints = useBreakpoints();
    const isDesktop = breakpoints.greaterOrEqual("md");
    const { state, close } = useInstantCart();

    return {
      isDesktop,
      state,
      close,
    };
  },
});
</script>

<style lang="scss">
.cart-menu--mobile.v-overlay__content {
  bottom: 50px;
}
</style>

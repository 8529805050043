<template>
  <v-footer class="mobile-nav px-0!" tag="nav" height="50" flat>
    <div class="w-full">
      <v-divider />
      <div class="flex justify-between px-5 py-1">
        <div v-for="(route, i) in menu" :key="i">
          <v-badge
            overlap
            :content="route.content"
            :model-value="!!route.content"
            :offset-x="6"
            :offset-y="6"
            max="10"
            bordered
          >
            <v-btn
              class="mobile-nav-item"
              variant="text"
              size="small"
              :to="route.to"
              icon
              @click="handleClick(route)"
            >
              <NuxtIcon
                :name="isActiveRoute(route) ? route.activeIcon : route.icon"
                class="mobile-nav-icon"
              />
            </v-btn>
          </v-badge>
        </div>
      </div>
    </div>
  </v-footer>
  <InstantCart />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InstantCart from "@/components/elements/cart/InstantCart";

type RouteItem = {
  id: string;
  icon: string;
  activeIcon: string;
  to?: { name: string };
  content?: string | number;
};

export default defineComponent({
  name: "MobileNavFooter",
  components: {
    InstantCart,
  },
  setup() {
    const route = useRoute();
    const cart = useCart();
    const instantCart = useInstantCart();

    const menuRec = ref<Record<string, boolean>>({});

    const isActiveRoute = (item: RouteItem) => {
      const someActive = Object.values(menuRec.value).some((x) => x === true);
      if (!someActive && item.to) {
        const name = item.to.name;
        return route.matched.some((match) => match.name === name);
      }

      if (item.id === "cart") {
        // if(instantCart.state.value.open){
        //   menuRec.value[last] = true
        // }
        return instantCart.state.value.open;
      }

      return menuRec.value[item.id];
    };

    const handleClick = (route: RouteItem) => {
      Object.keys(menuRec.value).forEach((key) => {
        if (key != route.id) {
          menuRec.value[key] = false;
          if (route.id === "cart") {
            instantCart.close();
          }
        }
      });
      if (!route.to) {
        menuRec.value[route.id] = true;
        if (route.id === "cart") {
          instantCart.open();
        }
      }
    };

    const routes = computed((): RouteItem[] => [
      {
        id: "home",
        icon: "home",
        activeIcon: "home-filled",
        to: { name: "index" },
      },
      {
        id: "events",
        icon: "calendar",
        activeIcon: "calendar-filled",
        to: { name: "events" },
      },
      {
        id: "products",
        icon: "cube",
        activeIcon: "cube-filled",
        to: { name: "products" },
      },
      {
        id: "services",
        icon: "calendar-clock",
        activeIcon: "calendar-clock-filled",
        to: { name: "services" },
      },
      // {
      //   id: "search",
      //   icon: "search",
      //   activeIcon: "search-bold",
      // },
      {
        id: "cart",
        icon: "bag",
        activeIcon: "bag-filled",
        content: cart.itemsCount.value,
      },
    ]);

    return {
      menu: routes,
      isActiveRoute,
      handleClick,
      menuRec,
    };
  },
});
</script>

<style lang="scss">
.mobile-nav {
  height: 50px;
  width: 100vw;
  // z-index: 1007;
  // z-index: 1000;
  z-index: 2403;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.mobile-nav-icon {
  font-size: 1.5rem;
}
.mobile-nav-item.v-btn--active .v-btn__overlay {
  opacity: 0 !important;
}
</style>
